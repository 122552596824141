import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "welcheDokumenteBraucheIch";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    Um deine Finanzierungsanfrage bearbeiten zu können, benötigen wir zum einen Unterlagen, die uns
                    Informationen über unsere Kund:innen geben. Dazu zählen Reisepass und Meldezettel, aktuelle
                    Lohnzettel sowie Nachweise über verfügbare Eigenmittel. Besonders die letztgenannten Dokumente sind
                    zentral, damit wir uns einen ersten Überblick über deine finanzielle Situation verschaffen können.
                </p>
                <p>
                    Zum anderen benötigen wir Unterlagen, die Informationen über die Immobilie enthalten, deren Kauf du
                    planst. Unerlässlich für eine rasche Bearbeitung deiner Anfrage sind hier ein Exposé, das du von der
                    Makler:in erhältst und in dem alle wichtigen Eigenschaften der Immobilie angeführt werden, Fotos,
                    den Kaufvertrag, einen Energieausweis sowie den Grundbuchauszug.
                </p>
            </Article>

            {/*TODO: Linking to affordability Calculator, Linking to other articals*/}

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData page={key} heading={question} description={answer} datePublished="2022-09-12" dateModified="2022-09-12" />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
